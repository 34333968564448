import { render, staticRenderFns } from "./RoleManagementStaffManager.vue?vue&type=template&id=46680531&"
import script from "@/scripts/modal/systemManager/roleManagement/roleManagementStaffManager.js?vue&type=script&lang=js&"
export * from "@/scripts/modal/systemManager/roleManagement/roleManagementStaffManager.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports