import baseComponent from '@/scripts/baseComponent';
import authApi from '@/api/common/auth';
import { FUNCTION_CODE, API_CALL_STATUS } from "@/constant/config";
import { ROLE_MANAGEMENT_MODIFY_TYPE } from "@/constant/systemManagerStatus";
import { mapState } from "vuex";
import Decentralized from "../../../../views/components/systemManager/Decentralized.vue";
import CaretTop from "../../../../views/components/Icon/CaretTop.vue";
import CaretBottom from "../../../../views/components/Icon/CaretBottom.vue";
const queryString = require('query-string');

export default {

    components: {
        Decentralized,
        CaretTop,
        CaretBottom,
    },

    extends: baseComponent,

    data() {
        return {
            functionalGroups: [],
            decentralized: []
        };
    },

    props: {
        element: {
            type: Object,
            default: {}
        },
    },

    computed: {
        ...mapState({
            user: state => state.auth.user,
            listServicePack: state => state.common.listServicePack,
        }),
    },

    created() {
        this.getData();
    },

    mounted() {
        this.description = this.element.name;
    },

    methods: {

        //Child level 3 change
        childItemLevel3Change(item) {
            item.enabled = !item.enabled;

            //Proccess Child level 2 
            if (this.decentralized.filter(x => x.parentId === item.parentId && x.enabled === !item.enabled).length === 0) {
                this.decentralized.find(x => x.id === item.parentId).enabled = item.enabled;
            }
            if (this.decentralized.filter(x => x.parentId === item.parentId && x.enabled === item.enabled).length === 1) {
                this.decentralized.find(x => x.id === item.parentId).enabled = true;
            }
        },

        // Close this pop-up
        closePopup() {
            this.$emit("processingEvent", { event: "closePopup", data: "role-management-decentralized" });
        },

        // Get data
        getData() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            this.isCheckAll = false;
            authApi.decentralizedView(queryString.stringify({ roleId: this.element.id }))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        this.functionalGroups = responseData.functionalGroups;
                        this.decentralized = responseData.decentralized;
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    // location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // Modify role
        modifyRole() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            let request = {
                listDataDecentralized: this.decentralized.filter(x => x.enabled),
                roleId: this.element.id,
                type: ROLE_MANAGEMENT_MODIFY_TYPE.DECENTRALIZED,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            authApi.roleManagementModify(request)
                .then((res) => {
                    try {
                        this.closePopup();
                        setTimeout(() => {
                            this.$emit("processingEvent", { event: "performAction", data: { action: "refresh" } });
                        }, 500);
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },
    },
}