import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';
import { mapState } from "vuex";
import EventBus from "@/event/EventBus";
import { API_CALL_STATUS } from "@/constant/config";
import RoleManagementDecentralized from "../../../views/modal/systemManager/roleManagement/RoleManagementDecentralized.vue";
import RoleManagementStaffManager from "../../../views/modal/systemManager/roleManagement/RoleManagementStaffManager.vue";
import CaretTop from "../../../views/components/Icon/CaretTop.vue";
import CaretBottom from "../../../views/components/Icon/CaretBottom.vue";
const queryString = require('query-string');

export default {

    components: {
        RoleManagementDecentralized,
        RoleManagementStaffManager,
        CaretTop,
        CaretBottom,
    },

    extends: baseComponent,

    data() {
        return {
            isCheckAll: false,
            listData: [],
            pagination: {
                status: 1,
                querySearch: '',
                orderBy: 'A.NAME',
                directionSort: 'ASC',
                pageIndex: 1,
                pageSize: 50,
                outRowsNumber: 0,
            },

            modalCenterRoleManagementModifyDecentralized: "",
            roleManagementModifyDecentralizedKey: 0,

            modalCenterRoleManagementModifyStaff: "",
            roleManagementModifyStaffKey: 0,

            currentRole: {},
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
            systemManagerInformation: state => state.auth.systemManagerInformation,
        }),
    },

    created() {
        EventBus.$on("openPopup", this.openPopup);
        EventBus.$on("closePopup", this.closePopup);

        this.getData();
    },

    mounted() {
    },

    methods: {

        // Processing event
        processingEvent(param) {
            this[param.event](param.data);
        },

        // Open pop-up
        openPopup(param) {
            $(`#${param.type}`).modal({ backdrop: "static" });
            switch (param.type) {
                case "role-management-decentralized":
                    this.modalCenterRoleManagementModifyDecentralized = "d-flex";
                    this.roleManagementModifyDecentralizedKey++;
                    break;
                case "role-management-staff-manager":
                    this.modalCenterRoleManagementModifyStaff = "d-flex";
                    this.roleManagementModifyStaffKey++;
                    break;
            }
            this.currentRole = param.data;
        },

        // Close pop-up
        closePopup(popup) {
            $(`#${popup}`).modal("hide");
            switch (popup) {
                case "role-management-decentralized":
                    this.modalCenterRoleManagementModifyDecentralized = "";
                    break;
                case "role-management-staff-manager":
                    this.modalCenterRoleManagementModifyStaff = "";
                    break;
            }
        },

        // Selected all item
        selectedAll() {
            this.listData.forEach(element => {
                element.isChecked = this.isCheckAll;
            });
        },

        // Selected item
        selectedItem() {
            if (this.listData.filter(x => x.isChecked).length === this.listData.length) {
                this.isCheckAll = true;
            } else {
                this.isCheckAll = false;
            }
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refresh':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Search list
        searchList() {
            this.pagination.status = 0;
            if (this.pagination.querySearch) {
                this.pagination.querySearch = this.pagination.querySearch.substring(this.pagination.querySearch.lastIndexOf("@") + 1);
            }
            this.getData();
        },

        // Sort list
        sortList(element) {
            this.pagination.pageIndex = 1;
            this.pagination.orderBy = element;
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            this.getData();
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            this.pagination.pageIndex = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
        },

        // Pagination page change
        handleCurrentChange(element) {
            this.pagination.pageIndex = element;
            this.getData();
        },

        //Context menu action
        contextMenuAction(command) {
            switch (command.type) {
                case "role-management-decentralized":
                case "role-management-staff-manager":
                    this.openPopup(command);
                    break;
            }
        },

        // Get data
        getData() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            this.isCheckAll = false;
            authApi.roleManagementView(queryString.stringify(this.pagination))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = res.data.pagingItem;
                        responseData.data.forEach(element => {
                            element.isChecked = false;
                        });
                        this.listData = responseData.data;
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;

                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS, this.listData);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    // location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },
    }
}