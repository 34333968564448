var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"no-select d-flex flex-column m-3"},[_c('input',{staticClass:"position-fixed top-0 left-0",staticStyle:{"display":"none","color":"#fff","z-index":"99999","opacity":"0"},attrs:{"type":"text","id":"userCopy"}}),_c('div',{staticClass:"d-flex flex-row justify-content-between no-select"},[_vm._m(0),_c('div',[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.closePopup()}}},[_c('i',{staticClass:"el-icon-close button-close-popup",staticStyle:{"font-size":"1.5rem"}})])])]),_c('VuePerfectScrollbar',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"d-flex flex-column mt-3",staticStyle:{"height":"70vh","background-color":"white"}},_vm._l((_vm.functionalGroups),function(itemGroup){return _c('div',{key:itemGroup.id,staticClass:"d-flex flex-column ml-3 pb-4"},[_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center pb-1 mb-1",staticStyle:{"border-bottom":"1px solid #bbdefb","font-weight":"600"}},[_c('div',{staticClass:"d-flex flex-row align-items-center",staticStyle:{"color":"#9c27b0","font-size":"0.9rem"}},[_c('div',[_vm._v(_vm._s(itemGroup.id)+".")]),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(itemGroup.name)+" ")])]),_c('div',{staticClass:"ml-4",staticStyle:{"color":"#ef5350","font-size":"1rem"}},[_vm._v(" "+_vm._s(_vm.decentralized.filter( (x) => x.groupId === itemGroup.id && x.enabled ).length)+" / "+_vm._s(_vm.decentralized.filter((x) => x.groupId === itemGroup.id).length)+" ")])]),_vm._l((_vm.decentralized.filter(
          (x) => x.groupId === itemGroup.id && x.parentId === null
        )),function(childItemLevel1){return _c('div',{key:childItemLevel1.id,staticClass:"d-flex flex-column mt-2"},[_c('div',{staticStyle:{"cursor":"pointer"},style:(`${
            childItemLevel1.enabled
              ? ''
              : 'filter: grayscale(100%); opacity: 0.75;'
          }`),on:{"click":function($event){childItemLevel1.enabled = !childItemLevel1.enabled}}},[_c('Decentralized',{attrs:{"element":childItemLevel1,"index":null,"info":`${
              _vm.decentralized.filter(
                (x) => x.parentId === childItemLevel1.id && x.enabled
              ).length
            } / ${
              _vm.decentralized.filter((x) => x.parentId === childItemLevel1.id)
                .length
            }`}})],1),_c('div',{style:(`${
            childItemLevel1.enabled
              ? ''
              : 'filter: grayscale(100%); opacity: 0.75;'
          }`)},_vm._l((_vm.decentralized.filter(
              (x) => x.parentId === childItemLevel1.id
            )),function(childItemLevel2,indexLevel2){return _c('div',{key:childItemLevel2.id,staticClass:"d-flex flex-column mt-2"},[_c('div',{staticStyle:{"cursor":"pointer"},style:(`${
                childItemLevel2.enabled
                  ? ''
                  : 'filter: grayscale(100%); opacity: 0.75;'
              }`),on:{"click":function($event){childItemLevel2.enabled = !childItemLevel2.enabled}}},[_c('Decentralized',{attrs:{"element":childItemLevel2,"index":`${itemGroup.id}.${indexLevel2 + 1}`,"info":`${
                  _vm.decentralized.filter(
                    (x) => x.parentId === childItemLevel2.id && x.enabled
                  ).length
                } / ${
                  _vm.decentralized.filter(
                    (x) => x.parentId === childItemLevel2.id
                  ).length
                }`}})],1),_c('div',{style:(`${
                childItemLevel2.enabled
                  ? ''
                  : 'filter: grayscale(100%); opacity: 0.75;'
              }`)},_vm._l((_vm.decentralized.filter(
                  (x) => x.parentId === childItemLevel2.id
                )),function(childItemLevel3,indexLevel3){return _c('div',{key:childItemLevel3.id,staticClass:"d-flex flex-column mt-2"},[_c('div',{staticStyle:{"cursor":"pointer"},style:(`${
                    childItemLevel3.enabled
                      ? ''
                      : 'filter: grayscale(100%); opacity: 0.75;'
                  }`),on:{"click":function($event){return _vm.childItemLevel3Change(childItemLevel3)}}},[_c('Decentralized',{attrs:{"element":childItemLevel3,"index":`${itemGroup.id}.${indexLevel2 + 1}.${
                      indexLevel3 + 1
                    }`}})],1)])}),0)])}),0)])})],2)}),0),_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-3",staticStyle:{"background-color":"white"}},[_c('el-button',{attrs:{"size":"small","type":"primary","round":""},on:{"click":_vm.modifyRole}},[_c('span',{staticClass:"ml-3 mr-3"},[_vm._v("Confirm")])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-row align-items-center",staticStyle:{"color":"#1976d2"}},[_c('div',[_c('i',{staticClass:"el-icon-set-up",staticStyle:{"font-size":"1rem"}})]),_c('div',[_c('span',{staticClass:"ml-2",staticStyle:{"font-size":"0.85rem"}},[_vm._v("Decentralized ...")])])])
}]

export { render, staticRenderFns }